<template>
    <div id="form-coordinate-stickers-component">
        <p class="font-color-light font-14">{{ $t('form_coordinate_stickers_component.details_delivery') }}</p>

        <v-text-field v-model="receiver_reason" :rules="[rules.required]"
                      class="white-text-field" filled :label="$t('form_coordinate_stickers_component.reason')"
        />

        <v-text-field v-model="receiver_full_name" :rules="[rules.required, rules.length_5]"
                      class="white-text-field" filled :label="$t('form_coordinate_stickers_component.full_name')"
        />

        <vue-phone-number-input v-model="receiver_phone_number" :required="true" :translations="translations"
                                class="mb-5" size="lg" v-on:update="setValidPhone"
        />

        <v-text-field v-model="receiver_email" :rules="[rules.required, rules.email]"
                      class="white-text-field" filled :label="$t('form_coordinate_stickers_component.email')"
        />

        <v-btn :disabled="!valid" @click="$emit('goToStepThree')" block class="mb-3" color="primary" elevation="0"
               large>
            <span :class="!valid ? 'font-weight-bold' : 'secondary--text font-weight-bold'">
                {{ $t('form_coordinate_stickers_component.next') }}
            </span>
        </v-btn>

        <v-btn block color="primary" text @click="navigateTo('stickers')">
            <span class="primary--text font-weight-bold">{{ $t('global.cancel') }}</span>
        </v-btn>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    name: 'FormCoordinateStickersComponent',
    components: {VuePhoneNumberInput},
    computed: {
        valid() {
            return this.receiver_reason !== ''
                && this.receiver_full_name !== ''
                && this.valid_phone_number
                && this.email_pattern.test(this.receiver_email);
        }
    },
    data() {
        return {
            translations: {
                countrySelectorLabel: this.$t('form_coordinate_stickers_component.code_country'),
                countrySelectorError: this.$t('form_coordinate_stickers_component.choose_country'),
                phoneNumberLabel: this.$t('form_coordinate_stickers_component.phone_number'),
                example: this.$t('form_coordinate_stickers_component.example'),
            },
            valid_phone_number: false,
            email_pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            phone_pattern: /^[0-9]{10}$/,
            rules: {
                required: value => !!value || this.$t('global.field_required'),
                email: value => {
                    const pattern = this.email_pattern
                    return pattern.test(value) || this.$t('form_coordinate_stickers_component.email_not_valid')
                },
                phone: value => {
                    const pattern = this.phone_pattern
                    return pattern.test(value) || this.$t('form_coordinate_stickers_component.phone_number_not_valid')
                },
                length_5: value => value.length > 5 || this.$t('form_coordinate_stickers_component.minimum_character_required')
            },

            receiver_reason: '',
            receiver_full_name: '',
            receiver_email: '',
            receiver_phone_number: '',
        }
    },
    methods: {
        setValidPhone(value) {
            this.valid_phone_number = value.isValid
        }
    },
}
</script>
<style>
.input-tel__input {
    border: none !important;
    border-bottom: 1px solid #7f828a !important;
    border-radius: 0 !important;
    height: 56px !important;
}

.input-tel__input:focus {
    border: none !important;
}

.country-selector__input {
    border: none !important;
    border-bottom: 1px solid #7f828a !important;
    border-right: 1px solid black !important;
    border-radius: 0 !important;
    height: 56px !important;

}
</style>