<template>
    <div id="form-address-stickers-component">
        <p class="font-color-light font-14 mb-8">
            {{ $t('form_address_stickers_component.choose_stickers_number') }}
        </p>

        <p class="font-color-light font-14 mb-3">{{ $t('form_address_stickers_component.address_delivery') }}</p>

        <GmapAutocomplete :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                          class="w-100" :placeholder="$t('form_address_stickers_component.address')"
                          @place_changed="setGoogleAddress" :value="address_postal"
        />

        <v-text-field v-model="address_complement" class="white-text-field mb-3"
                      filled full-width :label=" $t('form_address_stickers_component.address_complement')" hide-details
        />

        <v-row class="ma-0 justify-space-between">
            <v-text-field v-model="code_postal" :rules="[rules.required, rules.postal_code]"
                          class="white-text-field triple-text-field" filled
                          :label="$t('form_address_stickers_component.postal_code')"
            />

            <v-text-field v-model="city" :rules="[rules.required]"
                          class="white-text-field triple-text-field" filled
                          :label="$t('form_address_stickers_component.city')"
            />

            <v-text-field v-model="country" :rules="[rules.required]"
                          class="white-text-field triple-text-field" filled
                          :label="$t('form_address_stickers_component.country')"
            />

            <v-btn :disabled="!valid" block class="mb-3" color="primary" elevation="0" large
                   @click="$emit('prepareNewOrderSticker')"
            >
                <span :class="!valid ? 'font-weight-bold' : 'secondary--text font-weight-bold'">
                    {{ $t('global.validate') }}
                </span>
            </v-btn>

            <v-btn block color="primary" text @click="$router.push('stickers/' + false)">
                <span class="primary--text font-weight-bold">{{ $t('global.cancel') }}</span>
            </v-btn>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'FormAddressStickersComponent',
    data() {
        return {
            postal_code_pattern: /^[0-9]{5}$/,
            rules: {
                required: value => !!value || this.$t('global.field_required'),
                postal_code: value => {
                    const pattern = this.postal_code_pattern;
                    return pattern.test(value) || this.$t('form_address_stickers_component.code_postal_not_valid');
                },
                length_5: value => value.length > 5 || this.$t('form_address_stickers_component.minimum_character_required')
            },

            address_postal: '',
            address_complement: '',
            code_postal: '',
            city: '',
            country: ''
        }
    },
    computed: {
        valid() {
            return this.address_postal !== '' && this.country !== '' && this.city !== '' && this.postal_code_pattern.test(this.code_postal);
        }
    },
    methods: {
        setGoogleAddress(address) {
            let count = 0;
            address.address_components.forEach(component => {
                if (component.types.includes('street_number')) {
                    count++;
                } else if (component.types.includes('route')) {
                    count++;
                } else if (component.types.includes('locality')) {
                    count++;
                } else if (component.types.includes('country')) {
                    count++;
                } else if (component.types.includes('postal_code')) {
                    count++;
                }
            });

            this.address_postal = address.formatted_address;
            address.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    this.city = component.long_name;
                } else if (component.types.includes('country')) {
                    this.country = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    this.code_postal = component.long_name;
                }
            });

            if (count < 5) {
                this.showSnackbar('error', this.$t('form_address_stickers_component.address_not_valid'));
            }
        },
    }
}
</script>

<style>
.triple-text-field {
    max-width: 32% !important;
}

.pac-target-input {
    background-color: white;
    padding: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #7f828a;
}

</style>