<template>
    <div id="asking-stickers-page">
        <toolbar-back-button-component title="Faire une demande de stickers"/>
        <v-stepper v-model="form_step" alt-labels elevation="0" height="100px">
            <v-stepper-header>
                <v-stepper-step :step="1" class="px-0" editable>
                    <span :class="form_step === 1 ? 'secondary--text' : 'font-color-light'">
                        {{ $t('asking_stickers_page.stickers')}}
                    </span>
                </v-stepper-step>

                <v-divider class="secondary"/>

                <v-stepper-step :step="2" class="px-0" :editable="form_step >=2">
                    <span :class="form_step === 2 ? 'secondary--text' : 'font-color-light'">
                        {{ $t('asking_stickers_page.coordinates')}}
                    </span>
                </v-stepper-step>

                <v-divider class="secondary"/>

                <v-stepper-step :step="3" class="px-0">
                    <span :class="form_step === 3 ? 'secondary--text' : 'font-color-light'">
                        {{ $t('asking_stickers_page.delivery')}}
                    </span>
                </v-stepper-step>
            </v-stepper-header>
        </v-stepper>

        <div class="mb-16 pa-3">
            <div v-if="form_step === 1">
                <form-list-stickers-component ref="formListStickersComponent" v-on:goToStepTwo="goToStepTwo"/>
            </div>
            <div v-else-if="form_step === 2">
                <form-coordinate-stickers-component ref="formCoordinateStickersComponent"
                                                    v-on:goToStepThree="goToStepThree"
                />
            </div>
            <div v-else-if="form_step === 3">
                <form-address-stickers-component ref="formAddressStickerComponent"
                                                 v-on:prepareNewOrderSticker="prepareNewOrderSticker"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";
import FormListStickersComponent from "@/components/Sticker/FormListStickersComponent";
import FormCoordinateStickersComponent from "@/components/Sticker/FormCoordonateStickersComponent";
import FormAddressStickersComponent from "@/components/Sticker/FormAddressStickersComponent";
import OrderStickerRepository from "@/repositories/OrderStickerRepository";

export default {
    name: 'AskingStickersPage',
    created() {
        this.userHasAccess('PWA_STICKERS', this.role_edit, true);
    },
    components: {
        FormAddressStickersComponent,
        FormCoordinateStickersComponent, FormListStickersComponent, ToolbarBackButtonComponent
    },
    data() {
        return {
            form_step: 1,

            order_referentiel_stickers: [],

            new_order_sticker: {
                stickers_to_order: [],
                total_vehicle_concerned: 0,
                receiver_reason: '',
                receiver_full_name: '',
                receiver_email: '',
                receiver_phone_number: '',
                address_postal: '',
                address_complement: '',
                code_postal: '',
                city: '',
                country: '',
            }
        }
    },
    methods: {
        goToStepTwo() {
            this.new_order_sticker.stickers_to_order = this.$refs.formListStickersComponent.stickers_to_order;
            this.new_order_sticker.total_vehicle_concerned = this.$refs.formListStickersComponent.total_vehicle_concerned;
            this.form_step = 2;
        },

        goToStepThree() {
            this.new_order_sticker.receiver_reason = this.$refs.formCoordinateStickersComponent.receiver_reason;
            this.new_order_sticker.receiver_full_name = this.$refs.formCoordinateStickersComponent.receiver_full_name;
            this.new_order_sticker.receiver_email = this.$refs.formCoordinateStickersComponent.receiver_email;
            this.new_order_sticker.receiver_phone_number = this.$refs.formCoordinateStickersComponent.receiver_phone_number;
            this.form_step = 3;
        },

        prepareNewOrderSticker() {
            this.new_order_sticker.address_postal = this.$refs.formAddressStickerComponent.address_postal;
            this.new_order_sticker.address_complement = this.$refs.formAddressStickerComponent.address_complement;
            this.new_order_sticker.code_postal = this.$refs.formAddressStickerComponent.code_postal;
            this.new_order_sticker.city = this.$refs.formAddressStickerComponent.city;
            this.new_order_sticker.country = this.$refs.formAddressStickerComponent.country;

            this.addNewOrderSticker();
        },

        addNewOrderSticker() {
            OrderStickerRepository.postOrderSticker(this.new_order_sticker).then(() => {
                this.$router.push('stickers?show_alert=true');
            }).catch((error) => {
                this.manageError(error);

                if (error.response.status === 409) {
                    this.$router.push('stickers?show_alert=true');
                }
            });
        }
    }
}
</script>

<style>
.v-stepper__label {
    visibility: visible !important;
    display: block !important;
    margin-top: 3px !important;
}

.v-stepper--alt-labels .v-stepper__step {
    flex-basis: 160px !important;
}
</style>